import "focus-visible/dist/focus-visible";
import "overlayscrollbars/css/OverlayScrollbars.css";

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import LogRocket from "logrocket";
import { SSRKeycloakProvider, SSRCookies } from "@react-keycloak/ssr";
import Router from "next/router";
import { ThemeProvider, generateTheme } from "design-system";
import { DefaultSeo, NextSeo } from "next-seo";
import NextApp from "next/app";
import { QueryClient, QueryClientProvider } from "react-query";

import AppConfig from "~/configs/next";
import NextNProgress from "~/components/NextNProgress";
import SessionExpiryDialog from "~/components/SessionExpiryDialog";
import { AnalyticsProvider, Analytics } from "~/utils/analytics";
import WebOnly from "~/components/WebOnly";
import { PAGE_TYPES } from "~/constants/page-types";
import { SEO } from "~/configs/seo";
import { KEYCLOAK } from "~/configs/keycloak";
import { LayoutProvider } from "~/contexts/layout";
import useAuth from "~/hooks/useAuth";
import { wrapper } from "~/redux/configureStore";
import PermissionsRequired, {
  DefaultPermissionsFallback,
} from "~/components/PermissionsRequired";
import { AppLoader } from "~/components/AppLoader";
// import { PageBehaviour } from "~/components/Page";
import { TreasuredClientProvider } from "~/contexts/treasured-client";

// Track initial pageview on client-side
if (typeof window !== "undefined") {
  Analytics.page();
}

// Track pageview on route change
Router.events.on("routeChangeComplete", () => {
  Analytics.page();
});

function useLogRocket({ currentUser, enabled = false }) {
  useEffect(() => {
    if (AppConfig.LOGROCKET_API_KEY) {
      LogRocket.init(AppConfig.LOGROCKET_API_KEY);
      // LogRocket.init(AppConfig.LOGROCKET_API_KEY, {
      //   mergeIframes: true,
      // });
    }
  }, []);

  useEffect(() => {
    if (!enabled || !currentUser || !AppConfig.LOGROCKET_API_KEY) {
      return;
    }

    const { id, firstName, lastName } = currentUser;
    LogRocket.identify(id, { name: `${firstName} ${lastName}` });
  }, [currentUser, enabled]);
}

function LogRocketComponent() {
  const { isLoggedIn, currentUser } = useAuth();
  const isAppReady = !isLoggedIn || currentUser;
  useLogRocket({ currentUser, enabled: isAppReady });

  return null;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App({ Component, pageProps, router }) {
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const pageType =
    pageProps.pageType ?? Component.pageType ?? PAGE_TYPES.PRIVATE;

  const handleSuccessEvent = () => {
    setIsSessionExpired(false);
  };
  const handleErrorEvent = (_, error) => {
    throw error;
  };
  const handleRefreshSuccessEvent = () => setIsSessionExpired(false);
  const handleExpiryEvent = () => setIsSessionExpired(true);
  const handleUnknownEvent = (eventType) => {
    throw new Error(`Unknown Keycloak event type: ${eventType}`);
  };

  const handlers = {
    onReady: handleSuccessEvent,
    onAuthSuccess: handleSuccessEvent,
    onAuthError: handleErrorEvent,
    onAuthRefreshError: handleErrorEvent,
    onAuthRefreshSuccess: handleRefreshSuccessEvent,
    onAuthLogout: handleExpiryEvent,
    onTokenExpired: handleExpiryEvent,
  };

  const handleKeycloakEvent = (eventType, error) =>
    (handlers[eventType] || handleUnknownEvent)(eventType, error);

  const {
    headTitle,
    headImageUrl,
    headUrl,
    headDescription,
    // isOnboardingPage,
  } = pageProps;
  const { Layout, ...layoutProps } = {
    Layout: React.Fragment,
    ...Component.layoutProps,
  };

  return (
    <>
      <DefaultSeo
        titleTemplate={SEO.titleTemplate}
        description={SEO.description}
        openGraph={SEO.openGraph}
        twitter={SEO.twitter}
      />
      <NextSeo
        title={headTitle ?? layoutProps?.meta?.title}
        description={headDescription}
        openGraph={{
          title: headTitle ?? layoutProps?.meta?.title,
          type: "article",
          url: headUrl,
          images: [{ url: headImageUrl, alt: headTitle }],
          description: headDescription,
        }}
      />
      <QueryClientProvider client={queryClient}>
        <TreasuredClientProvider>
          <ThemeProvider
            theme={generateTheme({
              styles: {
                global: {
                  "html, body, #__next": {
                    height: "100%",
                    width: "100%",
                    overflow: "hidden",
                  },
                  a: {
                    color: "blue.400",
                  },
                },
              },
            })}
          >
            <SSRKeycloakProvider
              keycloakConfig={KEYCLOAK}
              initOptions={{
                // this embed check won't be needed once iframe is fixed
                onLoad: layoutProps.embed ? null : "check-sso",
                silentCheckSsoRedirectUri: layoutProps.embed
                  ? null
                  : `https://${AppConfig.HOST}/silent-check-sso.html`,
                // onLoad: "check-sso",
                // silentCheckSsoRedirectUri: `https://${AppConfig.HOST}/silent-check-sso.html`,
              }}
              persistor={SSRCookies()}
              onEvent={handleKeycloakEvent}
              LoadingComponent={<AppLoader />}
            >
              <AnalyticsProvider>
                <LayoutProvider>
                  <NextNProgress options={{ showSpinner: false }} />

                  {/* Page */}
                  <PermissionsRequired
                    pageType={pageType}
                    fallback={<DefaultPermissionsFallback />}
                  >
                    {/* <PageBehaviour isOnboardingPage={isOnboardingPage}> */}
                    <Layout {...layoutProps}>
                      <Component {...pageProps} key={router.route} />
                    </Layout>
                    {/* </PageBehaviour> */}
                  </PermissionsRequired>

                  <WebOnly
                    component={SessionExpiryDialog}
                    open={isSessionExpired}
                  />

                  <LogRocketComponent />
                </LayoutProvider>
              </AnalyticsProvider>
            </SSRKeycloakProvider>
          </ThemeProvider>
        </TreasuredClientProvider>
      </QueryClientProvider>
    </>
  );
}

App.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
  router: PropTypes.shape({
    route: PropTypes.string,
  }).isRequired,
  cookies: PropTypes.object,
};

App.getInitialProps = async (ctx) => {
  const appProps = await NextApp.getInitialProps(ctx);

  return { ...appProps };
};

export default wrapper.withRedux(App);
