import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import LogRocket from "logrocket";
import { createWrapper } from "next-redux-wrapper";

import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

const isProd = process.env.NODE_ENV === "production";
const isDev = process.env.NODE_ENV !== "production";

const bindMiddleware = (initialMiddleware) => {
  let middleware = [...initialMiddleware];

  // LogRocket should be the final middleware
  if (isProd) {
    middleware.push(LogRocket.reduxMiddleware());
  }

  if (isDev) {
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  } else {
    return applyMiddleware(...middleware);
  }
};

export function makeStore() {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(rootReducer, bindMiddleware([sagaMiddleware]));

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
}

export const wrapper = createWrapper(makeStore, { debug: isDev });
