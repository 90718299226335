import React from "react";
import { Center, Spinner, VStack, Text } from "design-system";

export function AppLoader() {
  return (
    <Center width="100%" height="100%">
      <VStack spacing={4}>
        <Spinner size="lg" />
        <Text fontSize="lg">Loading...</Text>
      </VStack>
    </Center>
  );
}
