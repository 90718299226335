import AppConfig from "./next";

export const SEO = {
  titleTemplate: "%s | Treasured",
  description:
    "Treasured Inc. Modern Online Exhibits for Showcasing History to Future Generations in a Digital Archive designed for Storytelling",
  openGraph: {
    type: "website",
    locale: "en_US",
    url: `https://${AppConfig.HOST}/`,
    site_name: "Treasured Inc.",
  },
  twitter: {
    handle: "@TreasuredTeam",
    site: "@TreasuredTeam",
    cardType: "summary_large_image",
  },
};
