import React from "react";
import PropTypes from "prop-types";
import { useKeycloak } from "@react-keycloak/ssr";
import {
  Text,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "design-system";

function SessionExpiryDialog({ open = true }) {
  const { keycloak } = useKeycloak();

  return (
    <Modal
      isOpen={open}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Session Expired</ModalHeader>

        <ModalBody>
          <Text>
            Hmm, looks like you&apos;ve signed out of another tab. No worries,
            just <strong>refresh the page</strong> or{" "}
            <strong>click the button below</strong> to log back in.
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button onClick={() => keycloak?.login()} colorScheme="brand">
            Login
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

SessionExpiryDialog.propTypes = {
  open: PropTypes.bool,
};

export default SessionExpiryDialog;
