import { HYDRATE } from "next-redux-wrapper";
import { combineReducers } from "redux";

import upload, { key as UPLOAD_KEY } from "./upload";

const combinedReducer = combineReducers({
  [UPLOAD_KEY]: upload,
});

const rootReducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };
    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};

export default rootReducer;
