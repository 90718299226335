import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

function WebOnly({ component: Component, children = null, ...props }) {
  const [isWeb, setIsWeb] = useState(true);

  useEffect(() => {
    setIsWeb(true);
  }, []);

  return isWeb ? <Component {...props}>{children}</Component> : children;
}

WebOnly.propTypes = {
  children: PropTypes.node,
  component: PropTypes.elementType,
};

export default WebOnly;
