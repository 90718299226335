import React from "react";
import PropTypes from "prop-types";
import { Text, Box, Button, VStack, Heading, Center } from "design-system";
import { useKeycloak } from "@react-keycloak/ssr";

import useAuth from "~/hooks/useAuth";
import { PAGE_ACCESS_PERMISSION } from "~/constants/permissions";
import { AppLoader } from "../AppLoader";

export function DefaultPermissionsFallback() {
  const { keycloak } = useKeycloak();

  return (
    <Center w="100%" h="100%">
      <VStack direction="column" maxWidth="md" textAlign="center" spacing={5}>
        <Box as="img" src="/static/images/undraw_security_o890.svg" />
        <Heading fontSize="2xl">Nothing to see here&hellip;</Heading>
        <Text>
          You do not have access to this page. Try logging in if you are the
          owner of this content.
        </Text>
        <Button
          colorScheme="brand"
          size="lg"
          isFullWidth
          onClick={() => keycloak.login()}
        >
          Login
        </Button>
      </VStack>
    </Center>
  );
}

function PermissionsRequired({
  children,
  pageType,
  fallback = <DefaultPermissionsFallback />,
}) {
  const { currentUser, isLoading } = useAuth();

  if (isLoading) {
    return <AppLoader />;
  }

  let hasRequiredPermissions = PAGE_ACCESS_PERMISSION({
    pageType,
    currentUser,
  });

  if (!hasRequiredPermissions) {
    return fallback;
  }

  return children;
}

PermissionsRequired.propTypes = {
  children: PropTypes.node,
  pageType: PropTypes.string,
  fallback: PropTypes.node,
};

export default PermissionsRequired;
