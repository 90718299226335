import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

export const LayoutContext = React.createContext({
  isSidebarOpen: false,
  setSidebarOpen: () => {},
});

export function useLayout() {
  return useContext(LayoutContext);
}

export function LayoutProvider({ children }) {
  const [open, setOpen] = useState(false);

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => setOpen(false);

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events]);

  return (
    <LayoutContext.Provider
      value={{ isSidebarOpen: open, setSidebarOpen: setOpen }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

LayoutProvider.propTypes = {
  children: PropTypes.node,
};
