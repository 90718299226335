import React, { useEffect, useRef } from "react";
import NProgress from "nprogress";
import Router from "next/router";
import PropTypes from "prop-types";

import { NProgressStyles } from "./NextNProgress.styles";

function NextNProgress({
  color = "#29D",
  height = 3,
  options = null,
  startPosition = 0.3,
  stopDelayMs = 200,
}) {
  const timer = useRef();

  const routeChangeStart = () => {
    NProgress.set(startPosition);
    NProgress.start();
  };

  const routeChangeEnd = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      NProgress.done(true);
    }, stopDelayMs);
  };

  useEffect(() => {
    timer.current = null;

    if (options) {
      NProgress.configure(options);
    }

    Router.events.on("routeChangeStart", routeChangeStart);
    Router.events.on("routeChangeComplete", routeChangeEnd);
    Router.events.on("routeChangeError", routeChangeEnd);

    return () => {
      clearTimeout(timer.current);
      Router.events.off("routeChangeStart", routeChangeStart);
      Router.events.off("routeChangeComplete", routeChangeEnd);
      Router.events.off("routeChangeError", routeChangeEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <NProgressStyles color={color} height={height} />;
}

NextNProgress.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  options: PropTypes.object,
  startPosition: PropTypes.number,
  stopDelayMs: PropTypes.number,
};

export default NextNProgress;
