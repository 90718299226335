/**
 *
 * This module provides an abstraction layer over our analytics infrastructure for
 * the rest of the application. By providing this abstraction, we hope to decouple
 * the application from any one implementation of analytics service (ex. Google
 * Analytics, Amplitude, Facebook, etc.) providing more flexibility and decoupling
 * if we decide to switch solutions in the future.
 *
 * The API for this module uses https://getanalytics.io/ as a base.
 *
 *
 * The main exports of this module are:
 *
 *  - `Analytics`         - this provides access to imperative analytics methods like
 *                          `identify`, `page`, and `track`
 *  - `useAnalytics`      - React hook for accessing analytics
 *  - `AnalyticsProvider` - Re-exported from `use-analytics` package
 *
 */

import Analytics from "analytics";
import googleAnalyticsPlugin from "@analytics/google-analytics";
import {
  AnalyticsProvider,
  useAnalytics as useAnalyticsOriginal,
} from "use-analytics";
import PropTypes from "prop-types";
import React from "react";

import config from "~/configs/next";

const plugins = [
  config.GA_TRACKING_CODE &&
    googleAnalyticsPlugin({
      trackingId: config.GA_TRACKING_CODE,
    }),
].filter(Boolean);

const analytics = Analytics({
  app: "world-web",
  version: 100,
  debug: process.env.NODE_ENV !== "production",
  plugins: plugins,
});

function PreinstantiatedAnalyticsProvider({ children = null }) {
  return <AnalyticsProvider instance={analytics}>{children}</AnalyticsProvider>;
}

PreinstantiatedAnalyticsProvider.propTypes = {
  children: PropTypes.node,
};

function useAnalytics() {
  const analytics = useAnalyticsOriginal();

  // We can add additional helper functions here

  return { ...analytics };
}

export default analytics;
export { analytics as Analytics };
export { PreinstantiatedAnalyticsProvider as AnalyticsProvider, useAnalytics };
